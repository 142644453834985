<template>
    <div class="all">
        <index-text-header></index-text-header>
        <div class="base-container" v-if="$route.path !== '/common'">
            <div class="breadcrumb">
                <div class="border-container"></div>
                <div class="link-container">
                    <Breadcrumb separator=">" style="color:#fff;">
                        <BreadcrumbItem to="/"
                            ><span class="link">{{ "首页" | language }}</span>
                        </BreadcrumbItem>
                        <BreadcrumbItem class="link last">{{
                            getText() | language
                        }}</BreadcrumbItem>
                    </Breadcrumb>
                </div>
            </div>
            <div class="body">
                <div class="left" v-if="ifShow">
                    <div class="menu" v-if="menu.length && menu[index].label">
                        <div class="title">
                            {{ menu[index].label | language }}
                        </div>
                        <div
                            class="menu-list"
                            v-if="menu[index].children.length"
                        >
                            <div
                                class="menu-item"
                                v-for="(item, i) in menu[index].children"
                                :key="item.src"
                                :class="{ active: i == child }"
                                v-if="item.show"
                                @click="goTo(item)"
                            >
                                {{ item.label | language }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <router-view />
                </div>
            </div>
        </div>
        <error :show="false" v-else></error>
        <index-footer></index-footer>
    </div>
</template>

<script>
import IndexTextHeader from "../compontents/index/IndexTestHeader";
import IndexFooter from "../compontents/index/IndexFooter";
import Error from "../compontents/Error.vue";
export default {
    components: {
        IndexTextHeader,
        IndexFooter,
        Error
    },

    data() {
        return {
            pageName: {
                login: "访客登录",
                register: "访客注册",
                search: "搜索",
                setting: "基本设置",
                notice: "消息通知",
                introduction: "中心简介",
                reserveDevice: "设备预约",
                reserveService: "场地预约"
            },
            index: 0,
            child: 0,
            menu: [
                {
                    label: "基本设置",
                    src: "/common/setting",
                    children: [
                        {
                            label: "基本设置",
                            src: "/common/setting",
                            name: "setting",
                            show: true
                        },
                        {
                            label: "消息通知",
                            src: "/common/notice",
                            name: "notice",
                            show: true
                        },
                        {
                            label: "设备预约",
                            src: "/common/reserve/device",
                            name: "reserveDevice",
                            show: true
                        },
                        {
                            label: "场地预约",
                            src: "/common/reserve/service",
                            name: "reserveService",
                            show: true
                        }
                    ]
                }
            ]
        };
    },
    mounted() {},
    computed: {
        ifShow() {
            let arr = ["setting", "notice", "reserveDevice", "reserveService"];
            if (arr.indexOf(this.$route.name) >= 0) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        goTo(item) {
            this.$router.push(item.src);
        },
        getText() {
            return this.pageName[this.$route.name];
        },

        getIndex() {}
    },
    watch: {
        $route: {
            handler: function(val) {
                let arr = [
                    "setting",
                    "notice",
                    "reserveDevice",
                    "reserveService"
                ];
                let name = this.$route.name;
                this.child = arr.indexOf(name);
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang="less" scoped>
.all {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .base-container {
        flex: 1;
        .breadcrumb {
            min-width: 1200px;
            position: relative;
            height: 100px;
            overflow: hidden;
            .border-container {
                // width: 0;
                // height: 0;
                border-color: transparent #00305b;
                border-width: 0 0 100px 200vw;
                border-style: solid;
            }
            .link-container {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 1200px;
                height: 22px;
                // background-color: red;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .link {
                    color: #2c8dfc;
                }
                .last {
                    color: #fff;
                }
            }
        }
        .body {
            width: 1200px;
            margin: 46px auto 0;
            display: flex;
            align-items: flex-start;
            // justify-content: space-between;
        }
        .left {
            min-width: 100px;
            .menu {
                min-width: 100px;
                text-align: left;
                .title {
                    height: 29px;
                    font-size: 18px;
                    font-weight: 600;
                    opacity: 0.3;
                    color: #000;
                    line-height: 29px;
                }
                .menu-list {
                    margin-top: 40px;
                    .menu-item {
                        height: 43px;
                        min-width: 100px;
                        padding: 10px 0;
                        font-size: 16px;
                        // font-weight: 600;
                        user-select: none;
                        color: #000;
                        opacity: 0.6;
                        border-bottom: 1px solid #d1d4da;
                        &:hover {
                            color: #2c8dfc;
                            cursor: pointer;
                            opacity: 1;
                        }
                        &:last-child {
                            border-bottom: none;
                        }
                    }
                    .active {
                        color: #2c8dfc;
                        opacity: 1;
                    }
                }
            }
        }
        .right {
            margin-left: 60px;
            flex: 1;
        }
    }
}
@media screen and (max-width: 960px) {
    .all {
        .base-container {
            .breadcrumb {
                min-width: 100%;
            }
            .body {
                margin-top: 8vw;
                width: 100%;
                display: block;
                .left {
                    display: none;
                }
                .right {
                    margin-left: 0;
                    padding: 0 8vw;
                }
            }
        }
    }
}
</style>
